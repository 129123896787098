import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import VideoBanner from '../components/koble/VideoBanner'
import Quote from '../components/koble/Quote'
import KobleRightChoice from '../components/koble/KobleRightChoice'
import CenteredWordsAndText from '../components/koble/CenteredWordsAndText'
import TitleAndColumnedText from '../components/koble/TitleAndColumnedText'
import TitleAndList from '../components/koble/TitleAndList'
import WordsAndImageList from '../components/koble/WordsAndImageList'

// eslint-disable-next-line
export const IndexPageTemplate = ({
  videoBanner,
  scientificInvesting,
  result,
  quote,
  joinUs,
  newsletter,
  benefits
}) => {
  return (
    <div>
      <VideoBanner videoBanner={videoBanner} />
      <div className="divider"></div>
      <TitleAndColumnedText {...scientificInvesting} />
      <div className="sub-divider"></div>
      <WordsAndImageList {...result} />
      <div className="sub-divider"></div>
      <TitleAndList {...benefits} />
      <div className="divider"></div>
      <KobleRightChoice {...joinUs} />
      <Quote {...quote} />
      <CenteredWordsAndText {...newsletter} />
    </div>
  )
}

IndexPageTemplate.propTypes = {
  videoBanner: PropTypes.object,
  scientificInvesting: PropTypes.object,
  result: PropTypes.object,
  quote: PropTypes.object,
  joinUs: PropTypes.object,
  newsletter: PropTypes.object,
  benefits: PropTypes.object
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <IndexPageTemplate {...frontmatter} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        videoBanner {
          title {
            cssClass
            text
          }
          description
          note
          icon {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          video {
            extension
            publicURL

            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          cta {
            text
            rightIcon
            rightCircle
            link
            type
          }
        }
        scientificInvesting {
          title {
            text
          }
          text
        }
        result {
          title {
            cssClass
            text
          }
          steps {
            icon {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 40
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
            label
            text
          }
        }
        benefits {
          title {
            text
          }
          list {
            text
          }
        }
        quote {
          description
          note
        }
        joinUs {
          title {
            text
            cssClass
          }
          description
          ctaDetails {
            text
            link
            type
          }
          video {
            extension
            publicURL

            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
        newsletter {
          title {
            text
            cssClass
          }
          text
          cta {
            text
            link
            type
            rightCircle
            rightIcon
          }
        }
      }
    }
  }
`
