import React from 'react'
import Word from './Word'
import '../scss/title-and-columned-text.scss'
import { HTMLContent } from '../common/Content'

export default function TitleAndColumnedText(props) {
  const { title = [], text = [] } = props
  return (
    <div className="tact-container max-1024-container">
      <div className="subtitle words-container d-flex justify-content-center flex-column">
        {title.map((item) => (
          <Word key={item.text} {...item} />
        ))}
      </div>
      <div className="text-container">
        {text.map((t, index) => (
          <HTMLContent key={index} content={t}></HTMLContent>
        ))}
      </div>
    </div>
  )
}
