import React from 'react'
import Word from './Word'
import '../scss/title-and-list.scss'

export default function TitleAndList(props) {
  const { title = [], list = [] } = props
  return (
    <div className="tal-container max-1024-container">
      <div className="styled-text words-container d-flex justify-content-center flex-column">
        {title.map((item) => (
          <Word key={item.text} {...item} />
        ))}
      </div>
      <div className="list-container">
        <ul>
          {list.map(({ text }, index) => (
            <li key={index}>{text}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}
