import React from 'react'
import PropTypes from 'prop-types'
import '../scss/words-and-image-list.scss'
import RenderGatsByImage from '../common/RenderGatsByImage'
import Word from './Word'

export default function WordsAndImageList(props) {
  const { title, steps } = props

  return (
    <React.Fragment>
      <div className="wail-container max-1024-container">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-flex align-items-start styled-text">
                {title.map((item) => (
                  <Word key={item.text} {...item} />
                ))}
              </div>
            </div>
            <div className="col-12 col-md-6">
              {steps.map((item, index) => {
                return (
                  <div
                    className="d-md-flex align-items-center hiw-card"
                    key={index}
                  >
                    <div className="hiw-card-img">
                      <RenderGatsByImage
                        imageSource={item.icon}
                        alt={item.text}
                        height={40}
                        width={40}
                      />
                    </div>
                    <div className="hiw-card-text">
                      <b>{item.label}</b> - {item.text}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

WordsAndImageList.propTypes = {
  howItWorks: PropTypes.object
}
