import React from 'react'
import CTAButton from '../common/CTAButton'
import Word from './Word'
import '../scss/centered-words-and-text.scss'
import { HTMLContent } from '../common/Content'

export default function CenteredWordsAndText(props) {
  const { title = [], text, cta } = props
  return (
    <div className="cwat-container">
      <div className="styled-text words-container d-flex justify-content-center">
        {title.map((item) => (
          <Word key={item.text} {...item} />
        ))}
      </div>
      <div className="text-container d-flex flex-column">
        {text.map((t, index) => (
          <div key={index} className="justify-content-center text-center">
            <HTMLContent content={t}></HTMLContent>
          </div>
        ))}
      </div>
      <div className="cta-container d-flex align-items-center justify-content-center">
        <CTAButton {...cta} />
      </div>
    </div>
  )
}
